import React from 'react';
import { Link } from 'gatsby';
import Content, { HTMLContent } from '../components/Content';
import Image from '../components/Image';
import Title from '../components/Title';
import Button from '../components/Button';
import Layout from '../components/Layout';
import SnsShareButtons from '../components/SnsShareButtons';
import Seo from '../components/Seo';
import '../pages/fortune/style.sass';

export default props => {
  const PostContent = HTMLContent || Content;
  const { data } = props.pageContext;
  // console.log(data)
  // console.log(props)

  return (
    <Layout>
      <Seo
        title={data.title}
        description={data.description}
        href={props.location.href}
      />
      <Title>{data.title}</Title>

      <figure className="fortune__figure">
        <Image filename="img_normal.png" />
      </figure>

      <PostContent className="content" content={data.body} />

      <div className="fortuneSelect">
        <div className="fortuneSelect__message">
          もっと詳しくおしえてちょうだい
        </div>
        {data.selected.map((item, i) => {
          return (
            <div className="fortuneSelect__item" key={i}>
              <Button label={item.title} to={`/selected/${item.id}`} />
            </div>
          );
        })}
      </div>

      <div className="fortune__section">
        <SnsShareButtons
          location={props.location}
          title={`【夢占い】${data.title}。${data.description}`}
        />
      </div>
    </Layout>
  );
};
